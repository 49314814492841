<template>
  <div class="mobile-home-form">
    <v-row no-gutters>
      <v-col cols="12">
        <calendesk-information-message>
          {{ $trans("mobile_account_editor_home_image_message") }}
        </calendesk-information-message>
      </v-col>
    </v-row>
    <v-row no-gutters class="mobile-home-form__logo">
      <v-col cols="12">
        <calendesk-image-select
          v-model="form.settings.tenants_mobile_image_home"
          outline
          :select-type="selectType.LOGO"
          :label="$trans('mobile_home_form_logo_label')"
          :select-image-button-text="
            $trans('mobile_home_form_logo_select_button')
          "
          :display-remove-image-button="false"
          @input="changeImageClientConfigurationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-textarea
          v-model="form.translations.company_slogan_text"
          outlined
          rows="9"
          :label="$trans('mobile_home_form_slogan_label')"
          hide-details
          maxlength="180"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.go_to_login"
          :label="$trans('mobile_home_form_go_to_login_label')"
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.login_signup_question"
          :label="$trans('mobile_home_form_signup_button')"
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.translations.sign_up_button"
          :label="$trans('mobile_home_form_signup_button')"
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CalendeskImageSelect from "@/lib/calendesk-js-library/components/CalendeskImageSelect";
import selectType from "@/components/assets/selectType";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";

export default {
  name: "MobileHomeForm",
  components: {
    CalendeskInformationMessage,
    CalendeskImageSelect,
  },
  data() {
    return {
      form: {
        settings: {
          tenants_mobile_image_home: {},
        },
        translations: {
          company_slogan_text: "",
          login_signup_question: "",
          sign_up_button: "",
          go_to_login: "",
        },
      },
      selectType,
    };
  },
  computed: {
    ...mapGetters({
      getConfiguration: "mobileEditor/getConfiguration",
      getTranslations: "mobileEditor/getTranslations",
    }),
  },
  watch: {
    getConfiguration() {
      this.updateFields();
    },
    getTranslations() {
      this.updateFields();
    },
  },
  beforeMount() {
    this.updateFields();
  },
  methods: {
    ...mapActions({
      changeConfiguration: "mobileEditor/changeConfiguration",
      updateTranslation: "mobileEditor/updateTranslation",
    }),
    updateFields() {
      if (!this.getConfiguration || !this.getTranslations) return;
      this.form.settings.tenants_mobile_image_home =
        this.getConfiguration.tenants_mobile_image_home;
      this.form.translations.company_slogan_text =
        this.getTranslations.company_slogan_text;
      this.form.translations.login_signup_question =
        this.getTranslations.login_signup_question;
      this.form.translations.sign_up_button =
        this.getTranslations.sign_up_button;
      this.form.translations.go_to_login = this.getTranslations.go_to_login;
    },
    updateTranslationInput() {
      this.updateTranslation({ translations: { ...this.form.translations } });
    },
    changeImageClientConfigurationsBlur() {
      this.changeConfiguration({
        settings: {
          tenants_mobile_image_home:
            this.form.settings.tenants_mobile_image_home,
        },
      });
    },
    changeTranslationsBlur() {
      this.changeConfiguration({ translations: { ...this.form.translations } });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-home-form .row {
  margin-bottom: 2rem;
}
</style>
